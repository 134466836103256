import React, { Component } from "react";
import {
  Form,
  Search,
  Label,
  Grid,
  Modal,
  Button,
  Segment,
  Table,
  Header,
} from "semantic-ui-react";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import PropTypes from "prop-types";
import Feathers from "../../redux/FeathersRedux";
// import css from './OrdersForm.css'
import Currency from "react-currency-formatter";
import PanelOrdersStepper from "./Stepper";
import { notify } from "react-notify-toast";
import swal from "sweetalert2";
import moment from "moment";
import { connect } from "react-redux";
import { push } from "react-router-redux";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

renderSearchResults.propTypes = {
  id: PropTypes.number,
  nombre: PropTypes.string,
};

class OrdersEditForm extends Component {
  constructor() {
    super();

    this.state = {
      generalLoading: false,
      loading: false,
      cliente: "",
      results: [],
      fecha_entrega: null,
      focused: false,
      newModalOpened: false,
      selectedPartida: null,
      pedido: {
        id: null,
        ref_sae: null,
        cliente_id: null,
        vendedor_id: 0,
        fecha_alta: moment().format("YYYY-MM-DD"),
        fecha_entrega: null,
        moneda_id: null,
        email_adicional: "",
        email: "",
        observaciones: "",
        validado: 0,
        flete_id: null,
        precio_flete: null,
        dir_entrega: "",
        col_entrega: "",
        municipio_entrega: "",
        estado_entrega: "",
        pais_entrega: null,
        responsable_entrega: "",
        status_id: 1,
        orden_compra: "",
        numero_salida: "",
      },
      productos: [],
      formulas: [],
      colores: [],
      fletes: [],
      partidas: [],
      partidas_to_add: [],
      productos_to_del: [],
      tipo_manchetas: [],
    };

    this.updatePedido = this.updatePedido.bind(this);
    this.fetchProducts = this.fetchProducts.bind(this);
    this.fetchFormulas = this.fetchFormulas.bind(this);
    this.fetchColors = this.fetchColors.bind(this);
    this.fetchFreights = this.fetchFreights.bind(this);
    this.parseDropdown = this.parseDropdown.bind(this);
    this.removeProducto = this.removeProducto.bind(this);
    this.openModal = this.openModal.bind(this);
    this.addProducto = this.addProducto.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.isOrderSent = this.isOrderSent.bind(this);
  }

  componentDidMount() {
    this.loadAndReload();
  }

  loadAndReload = async () => {
    await this.setState({
      generalLoading: true,
    });

    await this.fetchProducts();
    await this.fetchFormulas();
    await this.fetchColors();
    await this.fetchFreights();
    await this.fetchCurrentProduct();
    await this.fetchPartidas();
    await this.fetchTipoManchetas();

    await this.setState({
      generalLoading: false,
    });
  };

  fetchTipoManchetas = async () => {
    let tipo_manchetas = await Feathers.service("tipo_mancheta").find({
      query: {
        $limit: "-1",
        activo: 1,
      },
    });

    tipo_manchetas = tipo_manchetas.map((v) => {
      return {
        key: v.id,
        value: v.id,
        text: v.nombre,
      };
    });

    await this.setState({
      tipo_manchetas,
    });
  };

  fetchCurrentProduct = async () => {
    let order_id = this.props.match.params.id;
    let isIncidence = this.props.match.params.isIncidence || false;
    console.log(isIncidence, "is");
    let c_pedido = await Feathers.service("pedido").get(order_id);
    await this.setState({
      cliente: c_pedido.cliente.nombre,
      isIncidence: isIncidence,
      pedido: {
        ...this.state.pedido,
        id: c_pedido.id,
        ref_sae: c_pedido.ref_sae,
        cliente_id: c_pedido.cliente_id,
        vendedor_id: c_pedido.vendedor_id,
        fecha_entrega: moment(c_pedido.fecha_entrega),
        moneda_id: c_pedido.moneda_id,
        email_adicional: c_pedido.email_adicional,
        email: c_pedido.cliente.email,
        observaciones: c_pedido.observaciones,
        validado: c_pedido.validado,
        flete_id: c_pedido.flete_id,
        precio_flete: c_pedido.precio_flete,
        dir_entrega: c_pedido.dir_entrega,
        col_entrega: c_pedido.col_entrega,
        municipio_entrega: c_pedido.municipio_entrega,
        estado_entrega: c_pedido.estado_entrega,
        pais_entrega: c_pedido.pais_entrega,
        responsable_entrega: c_pedido.responsable_entrega,
        status_id: c_pedido.status_id,
        orden_compra: c_pedido.orden_compra,
        numero_salida: c_pedido.numero_salida,
      },
    });
  };

  fetchPartidas = async () => {
    let order_id = this.props.match.params.id;

    let partidas = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        pedido_id: order_id,
      },
    });

    /*
        tenemos que agregar la formula a cada una de las partidas
        porque el stepper luego la necesita.
        */

    let partidas_con_formula = [];

    for (let partida of partidas) {
      let formula = await Feathers.service("formula").find({
        query: {
          $limit: "-1",
          dureza_id: partida.dureza_id,
          material_id: partida.material_id,
        },
      });

      let dibujo = await Feathers.service("dibujo").find({
        query: {
          $limit: "-1",
          id: partida.dibujo_id,
        },
      });

      console.log(dibujo, "dibujo partida");
      partida.formula = formula[0];
      partida.dibujo = dibujo[0];

      if (partida.producto_id === 5) {
        // vamos a traer la información de la mancheta para agregar la información faltante

        let mancheta_original = await Feathers.service("manchetas").get(
          partida.mancheta_id
        );

        partida.parte_id = mancheta_original.parte;
        partida.tipo_mancheta_id = mancheta_original.tipo_mancheta_id;
        partida.forma_mancheta_id = mancheta_original.forma_mancheta_id;
        partida.perimetro = mancheta_original.perimetro_espec;
        partida.espesor = mancheta_original.espesor_espec;
        partida.altura = mancheta_original.altura_espec;
      }

      partidas_con_formula.push(partida);
    }

    await this.setState({
      partidas: partidas_con_formula,
    });
  };

  updatePedido(key, value) {
    this.setState({
      pedido: { ...this.state.pedido, [key]: value },
    });
  }

  _isOutsideRange = (a) => {
    let date1 = new Date(new Date().getTime() + 2.5 * 24 * 60 * 60 * 1000);
    let date2 = new Date(a._d);
    return date2 < date1;
  };

  handleResultSelect = (e, { result }) => {
    this.setState({ cliente: result.nombre }, () =>
      this.updatePedido("cliente_id", result.id)
    );
    Feathers.service("cliente")
      .find({
        query: {
          id: result.id,
        },
      })
      .then((res) => {
        this.setState({
          pedido: {
            ...this.state.pedido,
            email: res.data[0].email,
            dir_entrega: res.data[0].direccion,
            col_entrega: res.data[0].colonia,
            municipio_entrega: res.data[0].ciudad,
            estado_entrega: res.data[0].estado,
            pais_entrega: res.data[0].pais,
          },
        });
      });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loading: true, cliente: value });

    setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            loading: false,
            results: res.data,
          });
        });
    }, 500);
  };

  resetComponent = () =>
    this.setState({ loading: false, results: [], cliente: "" });

  parseDropdown(array, optionalId) {
    let pews = array.map((pew) => {
      let grr = {
        key: pew.nombre || pew.tipo_flete + " ($" + pew.precio + ")",
        text: pew.nombre || pew.tipo_flete + " ($" + pew.precio + ")",
        value: optionalId ? pew[optionalId] : pew.id,
      };

      if (pew.imagen) grr.image = { avatar: true, src: pew.imagen };
      return grr;
    });
    return pews;
  }

  fetchProducts() {
    Feathers.service("producto")
      .find({
        query: {
          $limit: "-1",
          activo: 1,
        },
      })
      .then((res) => {
        let prods = this.parseDropdown(res);
        this.setState({
          productos: prods,
        });
      });
  }

  fetchFormulas() {
    Feathers.service("formula")
      .find({
        query: {
          $limit: "-1",
        },
      })
      .then((res) => {
        this.setState({
          formulas: res.map((formula) => {
            return {
              key: formula.id,
              text: formula.nombre,
              value: formula,
            };
          }),
        });
      });
  }

  fetchColors() {
    Feathers.service("color")
      .find()
      .then((res) => {
        let forms = this.parseDropdown(res.data);
        this.setState({
          colores: forms,
        });
      });
  }

  fetchFreights() {
    Feathers.service("flete")
      .find()
      .then((res) => {
        let freights = this.parseDropdown(res.data);
        this.setState({
          fletes: freights,
        });
      });
  }

  addProducto(prod) {
    let prods = [...this.state.partidas];

    let prods_to_add = [...this.state.partidas_to_add];

    if (this.state.selectedPartida !== null) {
      if (prod.id === this.state.selectedPartida.id) {
        let index = prods.findIndex(
          (x) => x.id === this.state.selectedPartida.id
        );
        this.removeProducto(index, this.state.selectedPartida);
        prods.splice(index, 1);
      }
    }

    prods_to_add.push(prod);
    prods.push(prod);

    this.setState(
      {
        partidas: prods,
        partidas_to_add: prods_to_add,
      },
      () => {
        this.closeModal();
      }
    );
  }

  removeProducto(index, partida) {
    let productos_to_del = [...this.state.productos_to_del];
    productos_to_del.push(partida.id);

    let prods = [...this.state.partidas];
    prods.splice(index, 1);
    this.setState({
      partidas: prods,
      productos_to_del,
    });
  }

  openModal() {
    const { fecha_entrega, moneda_id } = this.state.pedido;
    if (!fecha_entrega || !moneda_id)
      return swal(
        "¡Espera!",
        "La fecha y la moneda son obligatorias para agregar un producto",
        "info"
      );

    this.setState({
      newModalOpened: true,
      selectedPartida: null,
    });
  }

  closeModal() {
    this.setState({
      newModalOpened: false,
    });
  }

  editOrder = async () => {
    let partidas = this.state.partidas.map((v) => {
      if (!this.state.isIncidence) {
        delete v.id;
      }

      delete v.dureza;
      delete v.dibujo;
      delete v.formula;
      delete v.parte_id;
      delete v.tipo_mancheta_id;
      delete v.forma_mancheta_id;
      delete v.perimetro;
      delete v.espesor;
      delete v.altura;
      delete v.unitario;
      //delete v.cantidad

      return v;
    });
    console.log("---", this.state);
    //console.log(partidas, "Partidas debug")

    let newOrder = {
      pedido: this.state.pedido,
      partidas,
    };
    //console.log(newOrder, "New order debug")

    await this.setState({
      generalLoading: true,
    });

    delete newOrder.pedido.email;
    newOrder.pedido.fecha_entrega = moment(
      newOrder.pedido.fecha_entrega
    ).format("YYYY-MM-DD");
    delete newOrder.pedido.fecha_alta;

    if (!this.state.isIncidence) {
      //What's to_del?
      let productos_to_del = [...this.state.productos_to_del];

      if (productos_to_del.length > 0) {
        productos_to_del.map(async (id) => {
          await Feathers.service("partida").remove(id);
        });
      }

      let partidas_to_add = [...this.state.partidas_to_add];
      //console.log(partidas_to_add, "partidas a crear")

      if (partidas_to_add.length > 0) {
        partidas_to_add.map(async (partida) => {
          partida.pedido_id = newOrder.pedido.id;
          await Feathers.service("partida").create(partida);
        });
      }
    } else {
      console.log("updating...", this.state.partidas);
      this.state.partidas.map(async (partida) => {
        partida.pedido_id = newOrder.pedido.id;

        await Feathers.service("partida").patch(partida.id, partida);
      });
    }

    Feathers.service("pedido")
      .patch(newOrder.pedido.id, newOrder.pedido)
      .then(async (res) => {
        await Feathers.service("notificacion")
          .patch(
            null,
            { adjunto: null, status: 0 },
            {
              adjunto: this.state.pedido.id,
            }
          )
          .finally(async () => {
            notify.show("Pedido actualizado", "success");
            // setTabby(0);
            this.props.onCreate();
          });
      })
      .catch((e) => {
        console.log(e)
        notify.show("Se produjo un error", "error");
      });

    this.props.dispatch(push("/orders"));
  };

  cleanForm = () => {
    this.setState({
      loading: false,
      cliente: "",
      results: [],
      fecha_entrega: null,
      focused: false,
      newModalOpened: false,
      pedido: {
        id: null,
        cliente_id: null,
        ref_sae: null,
        vendedor_id: this.props.auth.user.id,
        fecha_alta: moment().format("YYYY-MM-DD"),
        fecha_entrega: null,
        moneda_id: null,
        email_adicional: "",
        email: "",
        observaciones: "",
        validado: 0,
        flete_id: null,
        precio_flete: null,
        dir_entrega: "",
        col_entrega: "",
        municipio_entrega: "",
        estado_entrega: "",
        pais_entrega: null,
        responsable_entrega: "",
        status_id: 1,
        orden_compra: "",
        numero_salida: "",
      },
      partidas: [],
    });
  };

  updateProducto(index) {
    console.log("pedido", this.state.pedido);

    let prods = [...this.state.partidas];

    let productoSeleccionado = prods[index];
    console.log("debug", productoSeleccionado);

    this.setState({
      selectedPartida: productoSeleccionado,
      newModalOpened: true,
    });
  }

  findAndRenderProductName = (partida) => {
    const { producto_id } = partida;
    const { productos } = this.state;

    const found = productos.find((ele) => {
      if (+ele.value === +producto_id) return ele;
    });

    return found ? found.text : "---";
  };

  isOrderSent() {
    return this.state.pedido.status_id >= 5;
  }

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as={"h2"}>
                <Button
                  onClick={() => this.props.dispatch(push("/orders"))}
                  icon="arrow left"
                  style={{
                    backgroundColor: "transparent",
                    color: "#000000",
                  }}
                />
                Editar Orden
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment>
          <Form loading={this.state.generalLoading}>
            <Grid columns={2}>
              {/* LEFT PANEL */}
              <Grid.Column>
                <Grid.Row>
                  <Form.Group>
                    <Form.Field required width={16}>
                      <label>Cliente</label>
                      <Search
                        icon=""
                        disabled={this.isOrderSent()}
                        loading={this.state.loading}
                        value={this.state.cliente}
                        results={this.state.results}
                        onResultSelect={this.handleResultSelect}
                        onSearchChange={this.handleSearchChange}
                        resultRenderer={renderSearchResults}
                        placeholder="Cliente"
                        className="full-width"
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Row>
                <Grid.Row>
                  <Form.Group>
                    {/* <Form.Field width={5}>
                                            <label>REF de SAE</label>
                                            <Form.Input placeholder="SAE" value={this.state.pedido.ref_sae} onChange={(e ,d) => {
                                                this.setState({
                                                    pedido: {
                                                        ...this.state.pedido,
                                                        ref_sae: d.value
                                                    }
                                                })
                                        }} />
                                    </Form.Field> */}
                    <Form.Field required width={5}>
                      <label>Fecha Entrega</label>
                      <SingleDatePicker
                        disabled={this.isOrderSent() ? true : false}
                        date={this.state.pedido.fecha_entrega || ""}
                        onDateChange={(value) =>
                          this.updatePedido("fecha_entrega", value)
                        }
                        focused={this.state.focused}
                        onFocusChange={({ focused }) =>
                          this.setState({ focused })
                        }
                        numberOfMonths={1}
                        isOutsideRange={this._isOutsideRange}
                        block={true}
                      />
                    </Form.Field>
                    <Form.Dropdown
                      required
                      width={6}
                      label="Moneda"
                      selection
                      disabled={this.isOrderSent()}
                      value={this.state.pedido.moneda_id}
                      options={[
                        { key: 1, text: "MXN", value: 1 },
                        { key: 2, text: "USD", value: 2 },
                      ]}
                      placeholder="Moneda"
                      onChange={(e, { value }) =>
                        this.updatePedido("moneda_id", value)
                      }
                    />
                  </Form.Group>
                </Grid.Row>
                <Grid.Row>
                  <Form.Group>
                    <Form.Input
                      width={8}
                      disabled={this.isOrderSent()}
                      value={this.state.pedido.orden_compra}
                      label="Orden de compra"
                      name="orden_compra"
                      type="text"
                      placeholder="Orden de compra"
                      onChange={(e, { name, value }) =>
                        this.updatePedido(name, value)
                      }
                    />
                    <Form.Input
                      width={8}
                      value={this.state.pedido.numero_salida}
                      disabled={this.isOrderSent()}
                      label="Número de salida"
                      name="numero_salida"
                      type="text"
                      placeholder="Número de salida"
                      onChange={(e, { name, value }) =>
                        this.updatePedido(name, value)
                      }
                    />
                  </Form.Group>
                </Grid.Row>
                <Grid.Row>
                  <Form.Group>
                    <Form.Input
                      width={5}
                      value={this.state.pedido.email}
                      label="Email"
                      name="email"
                      // disabled={this.isOrderSent()}
                      type="text"
                      placeholder="N/A"
                      disabled
                    />
                    <Form.Input
                      width={5}
                      value={this.state.pedido.email_adicional}
                      label="Email Adicional"
                      name="email_adicional"
                      disabled={this.isOrderSent()}
                      type="text"
                      placeholder="N/A"
                      onChange={(e, { name, value }) =>
                        this.updatePedido(name, value)
                      }
                    />
                    <Form.Input
                      width={5}
                      value={this.state.pedido.observaciones}
                      label="Observaciones"
                      disabled={this.isOrderSent()}
                      name="observaciones"
                      type="text"
                      placeholder="N/A"
                      onChange={(e, { name, value }) =>
                        this.updatePedido(name, value)
                      }
                    />
                  </Form.Group>
                </Grid.Row>
                <Grid.Row>
                  <Form.Group>
                    <Form.Input
                      width={8}
                      value={this.state.pedido.dir_entrega}
                      label="Calle"
                      disabled={this.isOrderSent()}
                      name="dir_entrega"
                      type="text"
                      placeholder="Calle"
                      onChange={(e, { name, value }) =>
                        this.updatePedido(name, value)
                      }
                      required
                    />
                    <Form.Input
                      width={8}
                      value={this.state.pedido.col_entrega}
                      label="Colonia"
                      disabled={this.isOrderSent()}
                      name="col_entrega"
                      type="text"
                      placeholder="Colonia"
                      onChange={(e, { name, value }) =>
                        this.updatePedido(name, value)
                      }
                      required
                    />
                  </Form.Group>
                </Grid.Row>
                <Grid.Row>
                  <Form.Group>
                    <Form.Input
                      width={5}
                      value={this.state.pedido.municipio_entrega}
                      label="Municipio"
                      name="municipio_entrega"
                      disabled={this.isOrderSent()}
                      type="text"
                      placeholder="Municipio"
                      onChange={(e, { name, value }) =>
                        this.updatePedido(name, value)
                      }
                      required
                    />
                    <Form.Input
                      width={5}
                      value={this.state.pedido.estado_entrega}
                      label="Estado"
                      disabled={this.isOrderSent()}
                      name="estado_entrega"
                      type="text"
                      placeholder="Estado"
                      onChange={(e, { name, value }) =>
                        this.updatePedido(name, value)
                      }
                      required
                    />
                    <Form.Dropdown
                      required
                      width={6}
                      label="Pais"
                      disabled={this.isOrderSent()}
                      selection
                      value={this.state.pedido.pais_entrega}
                      options={[
                        { key: 1, text: "Mexico", value: "MX" },
                        { key: 2, text: "Estados Unidos", value: "US" },
                      ]}
                      placeholder="Pais"
                      onChange={(e, { value }) =>
                        this.updatePedido("pais_entrega", value)
                      }
                    />
                  </Form.Group>
                </Grid.Row>
                <Grid.Row>
                  <Form.Group>
                    <Form.Input
                      width={8}
                      value={this.state.pedido.responsable_entrega}
                      disabled={this.isOrderSent()}
                      label="Responsable"
                      name="responsable_entrega"
                      type="text"
                      placeholder="Responsable"
                      onChange={(e, { name, value }) =>
                        this.updatePedido(name, value)
                      }
                    />
                    <Form.Dropdown
                      width={8}
                      label="Flete"
                      selection
                      value={this.state.pedido.flete_id}
                      disabled={this.isOrderSent()}
                      options={this.state.fletes}
                      placeholder="Flete"
                      onChange={(e, { value }) =>
                        this.updatePedido("flete_id", value)
                      }
                      upward={true}
                    />
                  </Form.Group>
                </Grid.Row>
              </Grid.Column>
              {/* RIGHT PANEL */}
              <Grid.Column>
                <Grid.Row>
                  <Button
                    type="button"
                    content="Añadir Producto"
                    icon="add circle"
                    onClick={this.openModal}
                    disabled={
                      this.state.partidas.length === 1 || this.isOrderSent()
                    }
                  />
                  {/* Products */}
                  <Table singleLine>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Producto</Table.HeaderCell>
                        <Table.HeaderCell>Cantidad</Table.HeaderCell>
                        <Table.HeaderCell>Parte Mancheta</Table.HeaderCell>
                        {/*  <Table.HeaderCell>Formula</Table.HeaderCell>
                                            <Table.HeaderCell>Color</Table.HeaderCell> */}
                        <Table.HeaderCell>Peso</Table.HeaderCell>
                        <Table.HeaderCell>Precio</Table.HeaderCell>
                        <Table.HeaderCell>Editar</Table.HeaderCell>
                        <Table.HeaderCell>Remover</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.partidas.map((partida, index) => {
                        return (
                          <Table.Row key={index}>
                            <Table.Cell>
                              {this.findAndRenderProductName(partida)}
                            </Table.Cell>
                            <Table.Cell>
                              {partida.cantidad ? partida.cantidad : "---"}
                            </Table.Cell>
                            <Table.Cell>
                              {partida.parte_id ? partida.parte_id : "---"}
                            </Table.Cell>
                            {/* <Table.Cell>{partida.cantidad}</Table.Cell> */}
                            {/* <Table.Cell>{ partida.formula ? partida.formula.nombre : "---"   }</Table.Cell>
                                                    <Table.Cell>{this.state.colores[partida.color_id - 1] ? this.state.colores[partida.color_id - 1].text : "---" }</Table.Cell> */}
                            <Table.Cell>{partida.kilos} kg.</Table.Cell>
                            <Table.Cell>
                              <Currency
                                quantity={+partida.precio}
                                currency="USD"
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                icon="edit"
                                compact
                                size="mini"
                                positive
                                disabled={this.isOrderSent()}
                                onClick={() => this.updateProducto(index)}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                icon="close"
                                compact
                                size="mini"
                                negative
                                disabled={this.isOrderSent()}
                                onClick={() =>
                                  this.removeProducto(index, partida)
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Grid.Row>
              </Grid.Column>
            </Grid>
            <Grid.Row>
              <Grid.Column floated="right" width={5}>
                <Button
                  positive
                  floated="right"
                  labelPosition="left"
                  icon="pencil"
                  onClick={this.editOrder}
                  label="EDITAR ORDEN"
                  // disabled={this.state.partidas.length < 1 || this.isOrderSent()}
                />
              </Grid.Column>
            </Grid.Row>
          </Form>
        </Segment>

        <Modal open={this.state.newModalOpened}>
          <Modal.Header>
            Editar Producto
            <Button
              circular
              size="mini"
              basic
              icon="close"
              floated="right"
              onClick={this.closeModal}
            ></Button>
          </Modal.Header>
          <Modal.Content>
            <PanelOrdersStepper
              {...this.props}
              pedido={this.state.pedido}
              productos={this.state.productos}
              formulas={this.state.formulas}
              colores={this.state.colores}
              tipo_manchetas={this.state.tipo_manchetas}
              addProducto={this.addProducto}
              closeModal={this.closeModal}
              preloadedPartida={this.state.selectedPartida}
              partidas={this.state.partidas}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, null)(OrdersEditForm);
