import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Grid, Label, Button, Header, Form, Search } from "semantic-ui-react";
import CheckItemComponent from "../../components/Tasks/Checks/CheckItemComponent";
import Toggle from "react-toggle";
import "../../components/react-toggle.css";
import swal from "sweetalert2";
import Timer from "../../components/Timer";
import moment from "moment";
import CheckTask from "../../components/Tasks/Checks/CheckTask";
import { connect } from "react-redux";
import { exportToXls } from "../../utils/Utils";
import LoaderData from "../LoaderData";
import ObservationModal from "../../components/ObservationModal";
import { push } from "react-router-redux";
import TasksFilter from "../../components/FIlters/TasksFilter";

const renderClientResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderMaterialSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderSearchSaeResults = ({ id, ref_sae }) => (
  <Label key={id} content={ref_sae} />
);

class CheckContainer extends Component {
  timeout = null;

  constructor(props) {
    super(props);

    this.state = {
      checks: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 100,
      shouldExport: false,
      ///
      ready: false,
      task: undefined,
      loadingCheckButton: false,
      filterOptions: {
        client_id: null,
        ref_sae: null,
        material_id: null,
      },

      filter: {
        rodilloValue: null,
        partidaValue: null,
        pedidoValue: null,
        fechaEntrega: null,
      },

      // search bar objectssss

      client: null,
      material: null,
      sae: null,
      filters: [
        "costumer",
        "rodillo",
        "pedido",
        "partida",
        "entrega",
        "material",
      ],
      // search bar results

      clientResults: null,
      materialResults: null,
      saeResults: null,

      // search bar loadings

      clientLoading: null,
      materialLoading: null,
      saeLoading: null,
      interval: undefined,

      modalOpened: false,
      currentRow: {},
      columna: "",
      sortDirection: false,
      showSae: false,
      tableColumns: [
        {
          Header: "OT",
          headerStyle: { fontSize: 20 },
          id: "ref_sae",
          accessor: (d) => {
            if (d.pedido.ref_sae) return d.pedido.ref_sae;
            return "---";
          },
        },
        {
          Header: "Acciones",
          sortable: false,
          Cell: (row) => {
            row = row.original;
            return (
              <div>
                <Button
                  onClick={() => this.viewTask(row)}
                  icon="play"
                  basic
                  color="green"
                  content="Dar solución"
                  style={{ backgroundColor: "#0098f0" }}
                  size="tiny"
                />
              </div>
            );
          },
        },
        // {
        //   Header: "Rodillo",
        //   headerStyle: { fontSize: 20 },
        //   Cell: (row) => {
        //     return row.rodillo_id ? row.rodllo_id : "---"
        //   },
        // },
        {
          Header: "Partida",
          headerStyle: { fontSize: 16 },
          id: "partida_id",
          accessor: (d) => {
            if (d.pedido) {
              if (d.pedido.partidas)
                if (d.pedido.partidas.id) {
                  return d.pedido.partidas.id;
                }
            }
            return "---";
          },
        },
        {
          Header: "Rodillo",
          headerStyle: { fontSize: 16 },
          id: "rodillo_id",
          accessor: (d) => {
            return d.rodillo_id ? d.rodillo_id : "---";
          },
        },
        {
          Header: "Cliente",
          headerStyle: { fontSize: 16 },
          Cell: (row) => (
            <div>
              {row.original.pedido.cliente
                ? row.original.pedido.cliente.nombre
                : ""}
            </div>
          ),
        },
        {
          Header: "Fecha Ent.",
          headerStyle: { fontSize: 16 },
          Cell: (row) => (
            <div>
              {row.original.pedido.fecha_entrega
                ? moment(row.original.pedido.fecha_entrega).format("DD-MM-YYYY")
                : ""}
            </div>
          ),
        },
        {
          Header: "Material",
          headerStyle: { fontSize: 16 },
          id: "p-material",
          accessor: (d) => {
            if (!d.pedido.partidas) {
              return "---";
            }

            if (d.pedido.partidas.length) {
              return (
                <span>
                  {d.pedido.partidas[0].material
                    ? d.pedido.partidas[0].material.nombre
                    : "---"}
                </span>
              );
            } else {
              return (
                <span>
                  {d.pedido.partidas.material
                    ? d.pedido.partidas.material.nombre
                    : "---"}
                </span>
              );
            }
          },
        },
        {
          Header: "Dureza",
          headerStyle: { fontSize: 16 },
          maxWidth: 100,
          id: "p-dureza",
          accessor: (d) => {
            if (!d.pedido.partidas) {
              return "---";
            }

            if (d.pedido.partidas.length) {
              return (
                <span>
                  {d.pedido.partidas[0].dureza
                    ? d.pedido.partidas[0].dureza.valor
                    : "---"}
                </span>
              );
            } else {
              return (
                <span>
                  {d.pedido.partidas.dureza
                    ? d.pedido.partidas.dureza.valor
                    : "---"}
                </span>
              );
            }
          },
          //Cell: row => <span>{row.original.pedido.partidas[0].dureza.valor ? row.original.pedido.partidas[0].dureza.valor : '---'}</span>,
        },
        /* {
                    Header: "1er Producto",
                    headerStyle: { fontSize: 16 },
                    id: "p-producto",
                    accessor: (d) => {
                      if (!d.pedido.partidas) {
                        return "---";
                      }
                      if (d.pedido.partidas.length) {
                        return d.pedido.partidas[0].producto.nombre;
                      } else {
                        return d.pedido.partidas.producto.nombre;
                      }
                    },
                  }, */

        /* {
                    Header: "Cantidad",
                    maxWidth: 90,
                    headerStyle: { fontSize: 16 },
                    id: "p-cantidades",
                    accessor: (d) => {
                      if (!d.pedido.partidas) {
                        return "---";
                      }

                      if (d.pedido.partidas.length) {
                        return d.pedido.partidas[0].cantidad;
                      } else {
                        return d.pedido.partidas.cantidad;
                      }
                    },
                  }, */

        {
          Header: "DN",
          headerStyle: { fontSize: 16 },
          maxWidth: 90,
          id: "p-dn",
          accessor: (d) => {
            if (!d.pedido.partidas) {
              return "---";
            }

            if (d.pedido.partidas.length) {
              return (
                <span>
                  {d.pedido.partidas[0].dn ? d.pedido.partidas[0].dn : "---"}
                </span>
              );
            } else {
              return (
                <span>
                  {d.pedido.partidas.dn ? d.pedido.partidas.dn : "---"}
                </span>
              );
            }
          },
          //Cell: row => <span>{row.original.pedido.partidas[0].dn ? row.original.pedido.partidas[0].dn : '---'}</span>,
        },
        {
          Header: "DE",
          headerStyle: { fontSize: 16 },
          maxWidth: 90,
          id: "p-de",
          accessor: (d) => {
            if (!d.pedido.partidas) {
              return "---";
            }

            if (d.pedido.partidas.length) {
              return (
                <span>
                  {d.pedido.partidas[0].de ? d.pedido.partidas[0].de : "---"}
                </span>
              );
            } else {
              return (
                <span>
                  {d.pedido.partidas.de ? d.pedido.partidas.de : "---"}
                </span>
              );
            }
          },
          //Cell: row => <span>{row.original.pedido.partidas[0].de ? row.original.pedido.partidas[0].de : '---'}</span>,
        },
        {
          Header: "LC",
          headerStyle: { fontSize: 16 },
          maxWidth: 90,
          id: "p-lc",
          accessor: (d) => {
            if (!d.pedido.partidas) {
              return "---";
            }

            if (d.pedido.partidas.length) {
              return (
                <span>
                  {d.pedido.partidas[0].lc ? d.pedido.partidas[0].lc : "---"}
                </span>
              );
            } else {
              return (
                <span>
                  {d.pedido.partidas.lc ? d.pedido.partidas.lc : "---"}
                </span>
              );
            }
          },
          //Cell: row => <span>{row.original.pedido.partidas[0].lc ? row.original.pedido.partidas[0].lc : '---'}</span>,
        },
        {
          Header: "LT",
          headerStyle: { fontSize: 16 },
          maxWidth: 90,
          id: "p-lt",
          accessor: (d) => {
            if (!d.pedido.partidas) {
              return "---";
            }

            if (d.pedido.partidas.length) {
              return (
                <span>
                  {d.pedido.partidas[0].lt ? d.pedido.partidas[0].lt : "---"}
                </span>
              );
            } else {
              return (
                <span>
                  {d.pedido.partidas.lt ? d.pedido.partidas.lt : "---"}
                </span>
              );
            }
          },
          //Cell: row => <span>{row.original.pedido.partidas[0].lt ? row.original.pedido.partidas[0].lt : '---'}</span>,
        },
        {
          Header: "Kilos",
          headerStyle: { fontSize: 16 },
          maxWidth: 90,
          id: "kilos",
          accessor: (d) => {
            if (!d.pedido.partidas) {
              return "---";
            }

            if (d.pedido.partidas.length) {
              return (
                <span>
                  {d.pedido.partidas[0].kilos
                    ? d.pedido.partidas[0].kilos
                    : "---"}
                </span>
              );
            } else {
              return (
                <span>
                  {d.pedido.partidas.kilos ? d.pedido.partidas.kilos : "---"}
                </span>
              );
            }
          },
          //Cell: row => <span>{row.original.pedido.partidas[0].lt ? row.original.pedido.partidas[0].lt : '---'}</span>,
        },
        {
          Header: "Cantidad",
          headerStyle: { fontSize: 16 },
          maxWidth: 90,
          id: "cantidad",
          accessor: (d) => {
            if (!d.pedido.partidas) {
              return "---";
            }

            if (d.pedido.partidas.length) {
              return (
                <span>
                  {d.pedido.partidas[0].cantidad
                    ? d.pedido.partidas[0].cantidad
                    : "---"}
                </span>
              );
            } else {
              return (
                <span>
                  {d.pedido.partidas.cantidad
                    ? d.pedido.partidas.cantidad
                    : "---"}
                </span>
              );
            }
          },
          //Cell: row => <span>{row.original.pedido.partidas[0].lt ? row.original.pedido.partidas[0].lt : '---'}</span>,
        },
        // {
        //   Header: "Observaciones",
        //   headerStyle: { fontSize: 16 },
        //   maxWidth: 100,
        //   id: "ob",
        //   accessor: (d) => {
        //     if (!d.pedido.partidas.observaciones) {
        //       return "---";
        //     }

        //     if (d.pedido.partidas.length) {
        //       return (
        //         <span>
        //           {d.pedido.partidas[0].observaciones
        //             ? d.pedido.partidas[0].observaciones
        //             : "---"}
        //         </span>
        //       );
        //     } else {
        //       return (
        //         <span>
        //           {d.pedido.partidas.observaciones
        //             ? d.pedido.partidas.observaciones
        //             : "---"}
        //         </span>
        //       );
        //     }
        //   },
        // },
        { Header: "", sortable: false, Cell: this.renderActions },
        /* { Header: '', Cell: row => <Button onClick={ _ => this.handleInstaCheck(row)} loading={this.state.loadingCheckButton} disabled={this.state.loadingCheckButton}  basic color="pink" icon='flag checkered' size={"mini"} content='Revisar'  />} */
      ],
    };

    this.fetchData = this.fetchData.bind(this);
    // this.renderActions = this.renderActions.bind(this);
    this.initTask = this.initTask.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.restartTask = this.restartTask.bind(this);
    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.renderList = this.renderList.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.goBack = this.goBack.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.handleSaeToggle = this.handleSaeToggle.bind(this);
  }

  componentDidMount() {
    this.checkActiveTasks();
  }

  handleClientResultSelect = async (e, { result }) => {
    this.setState({ client: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        client_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleMaterialResultSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        material_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleResultSaeSelect = async (e, { result }) => {
    this.setState({ sae: result.ref_sae });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        ref_sae: result.ref_sae,
      },
    });

    this.fetchData(this.state);
  };

  handleClientSearchChange = async (e, { value }) => {
    await this.setState({ clientLoading: true, client: value });

    if (this.timeout) clearTimeout(this.timeout);
    if (this.state.client.length < 1) return; //this.resetClientComponent();

    this.timeout = setTimeout(() => {
      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 5,
          },
        })
        .then((res) => {
          this.setState({
            clientLoading: false,
            clientResults: res.data,
          });
        });
    }, 500);
  };

  handleSearchSaeChange = (e, { value }) => {
    this.setState({ saeLoading: true, sae: value });

    setTimeout(() => {
      if (this.state.sae.length < 1) return this.resetSaeComponent();

      Feathers.service("pedido")
        .find({
          query: {
            ref_sae: { $like: `%${value}%` },
            $limit: 5,
          },
        })
        .then((res) => {
          this.setState({
            saeLoading: false,
            saeResults: res.data,
          });
        });
    }, 500);
  };

  handleMaterialSearchChange = (e, { value }) => {
    this.setState({ materialLoading: true, material: value });

    setTimeout(() => {
      if (this.state.material.length < 1) return this.resetMaterialComponent();

      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState(
            {
              materialLoading: false,
              materialResults: res.data,
            },
            () => this.fetchData(this.state)
          );
        });
    }, 500);
  };

  resetClientComponent = () =>
    this.setState({ clientLoading: false, clientResults: [], client: "" });

  resetMaterialComponent = () =>
    this.setState({
      materialLoading: false,
      materialResults: [],
      material: "",
    });
  resetSaeComponent = () =>
    this.setState({ saeLoading: false, saeResults: [], sae: "" });

  checkActiveTasks() {
    Feathers.service("revision")
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          this.setState({
            task: res.data[0],
            ready: true,
          });
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  }

  handleSort = (array) => {
    let column = this.state.columna;
    console.log("col", column);
    switch (column.Header) {
      case "OT":
        array.data.sort((a, b) => {
          return a["pedido"]["ref_sae"] - b["pedido"]["ref_sae"];
        });
        break;
      case "Rodillo":
        array.data.sort((a, b) => {
          return a["rodillo_id"] - b["rodillo_id"];
        });
        break;
      case "Cliente":
        array.data.sort((a, b) => {
          const nameA = a["pedido"]["cliente"]["nombre"].toUpperCase(); // ignore upper and lowercase
          const nameB = b["pedido"]["cliente"]["nombre"].toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fecha Ent.":
        console.log("llll");
        array.data.sort((a, b) => {
          const nameA = a["pedido"]["fecha_entrega"].toUpperCase(); // ignore upper and lowercase
          const nameB = b["pedido"]["fecha_entrega"].toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Material":
        array.data.sort((a, b) => {
          let nameA = a.pedido.partidas.material
            ? a.pedido.partidas.material.nombre.toUpperCase()
            : ""; // ignore upper and lowercase
          let nameB = b.pedido.partidas.material
            ? b.pedido.partidas.material.nombre.toUpperCase()
            : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Dureza":
        array.data.sort((a, b) => {
          let A =
            a.pedido.partidas.dureza !== null
              ? a.pedido.partidas.dureza.valor
              : 0;
          let B =
            b.pedido.partidas.dureza !== null
              ? b.pedido.partidas.dureza.valor
              : 0;
          return A - B;
        });
        break;
      case "DN":
        array.data.sort((a, b) => {
          let A = a.pedido.partidas.dn;
          let B = b.pedido.partidas.dn;
          return A - B;
        });
        break;
      case "DE":
        array.data.sort((a, b) => {
          let A = a.pedido.partidas.de;
          let B = b.pedido.partidas.de;
          return A - B;
        });
        break;
      case "LC":
        array.data.sort((a, b) => {
          let A = a.pedido.partidas.lc;
          let B = b.pedido.partidas.lc;
          return A - B;
        });
        break;
      case "LT":
        array.data.sort((a, b) => {
          let A = a.pedido.partidas.lt;
          let B = b.pedido.partidas.lt;
          return A - B;
        });
        break;
      case "Kilos":
        array.data.sort((a, b) => {
          let A = a.pedido.partidas.kilos;
          let B = b.pedido.partidas.kilos;
          return A - B;
        });
        break;
      case "Cantidad":
        array.data.sort((a, b) => {
          let A = a.pedido.partidas.cantidad;
          let B = b.pedido.partidas.cantidad;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  fetchData = async (state = {}) => {
    let pageSize = state.pageSize || 109;

    let query = {
      $limit: state.pageSize,
      $skip: pageSize * state.page,
      $or: [
        { status_tarea_id: null },
        { status_tarea_id: 1 },
        //{incidencia_id: {$nin: null}}
      ],
      $sort: {
        id: -1,
      },
    };

    if (this.state.filterOptions.client_id !== null) {
      let pedidos_of_cliente = await Feathers.service("pedido").find({
        query: {
          status_id: {
            $ne: 6,
          },
          $limit: "-1",
          cliente_id: this.state.filterOptions.client_id,
        },
      });

      let pedidos_ids = pedidos_of_cliente.map((v) => v.id);

      query["pedido_id"] = {
        $in: pedidos_ids,
      };
    }

    if (this.state.filter.rodilloValue !== null) {
      query["rodillo_id"] = this.state.filter.rodilloValue;
    }

    // if (this.state.filter.partidaValue !== null) {
    //   query["partida"] = this.state.filter.partidaValue;
    // }

    if (this.state.filter.partidaValue !== null) {
      let pedidosPartida = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          id: this.state.filter.partidaValue,
        },
      });

      let pedidos_ids = pedidosPartida.map((v) => v.pedido_id);

      console.log("pedidos", pedidosPartida, pedidos_ids);

      query["pedido_id"] = {
        $in: pedidos_ids,
      };
    }

    if (this.state.filter.rodilloValue !== null) {
      query["rodillo_id"] = this.state.filter.rodilloValue;
    }

    if (this.state.filterOptions.material_id !== null) {
      let pedidosFecha = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          material_id: this.state.filterOptions.material_id,
        },
      });

      console.log("fecha", pedidosFecha);

      let pedidos_ids = pedidosFecha.map((v) => v.pedido_id);

      console.log("pedidos", pedidos_ids);

      query["pedido_id"] = {
        $in: pedidos_ids,
      };
    }

    // TODO
    // if (this.state.filter.partidaValue !== null) {
    //   query["partida"] = this.state.filter.partidaValue;
    // }

    if (this.state.filter.pedidoValue !== null) {
      query["pedido_id"] = this.state.filter.pedidoValue;
    }

    let pedidos_ids = [];

    if (this.state.filter.fechaEntrega !== null) {
      let pedidosFecha = await Feathers.service("pedido").find({
        query: {
          status_id: {
            $ne: 6,
          },
          $limit: "-1",
          fecha_entrega: {
            $lte: this.state.filter.fechaEntrega,
          },
        },
      });

      pedidos_ids = pedidosFecha.map((v) => v.id);
    }

    this.setState({ loading: true }, () => {
      Feathers.service("revision")
        .find({
          query,
        })
        .then((res) => {
          //res = this.handleSort(res);

          let newData = (res.data = res.data.filter((data) => {
            return data.incidencia ? !data.incidencia.solucion : false;
          }));

          if (this.state.filter.fechaEntrega && newData) {
            newData = newData.filter((task) =>
              pedidos_ids.includes(task.pedido_id)
            );
          }

          this.setState({
            shouldExport: false,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
            checks: newData,
          });

          // if (res.data) {
          //   if (this.state.columna === "") {
          //     res.data.sort((a, b) => {
          //       const nameA = a["pedido"]["fecha_entrega"].toUpperCase();
          //       const nameB = b["pedido"]["fecha_entrega"].toUpperCase();

          //       if (nameA < nameB) {
          //         return -1;
          //       }
          //       if (nameA > nameB) {
          //         return 1;
          //       }

          //       return 0;
          //     });
          //   }
          // } else {
          //   if (this.state.columna === "") {
          //     res.sort((a, b) => {
          //       const nameA = a["pedido"]["fecha_entrega"].toUpperCase();
          //       const nameB = b["pedido"]["fecha_entrega"].toUpperCase();

          //       if (nameA < nameB) {
          //         return -1;
          //       }
          //       if (nameA > nameB) {
          //         return 1;
          //       }

          //       return 0;
          //     });
          //   }
          // }

          // if (!this.state.shouldExport) {
          //   this.setState({
          //     checks: res.data,
          //     total: res.total,
          //     loading: false,
          //     page: state.page,
          //     pageSize: state.pageSize || 100,
          //   });
          // }
          // if (this.state.shouldExport) {
          //   exportToXls(res, "task_checks");
          //   this.setState({
          //     shouldExport: false,
          //     loading: false,
          //     page: state.page,
          //     pageSize: state.pageSize || 100,
          //   });
          // }
        });
    });
  };

  updateTask() {
    Feathers.service("revision")
      .get(this.state.task.id)
      .then((task) => this.setState({ task }));
  }

  goBack() {
    this.setState({ task: null });
  }

  resetMaterialComponent = () =>
    this.setState({ materialLoading: false, materialResult: [], material: "" });

  handleMaterialResultSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        material_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleMaterialSearchChange = async (e, { value }) => {
    await this.setState({ materialLoading: true, material: value });

    if (this.timeout) clearTimeout(this.timeout);
    if (this.state.material.length < 1) return; //this.resetClientComponent();

    console.log("---", value);

    this.timeout = setTimeout(() => {
      console.log("kqowkdq");
      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          console.log("res", res);
          this.setState({
            materialLoading: false,
            materialResults: res.data,
          });
        });
    }, 500);
  };

  viewTask(task) {
    console.log("task", this.props);
    if (task.incidencia_id) {
      this.props.dispatch(push("/tasks/incidences/view/" + task.incidencia_id));
    } else {
      swal({
        type: "error",
        title: "Incidencia no encontrada.",
        text: "Hubo un error al buscar la incidencia",
      });
      console.log("incidence not found");
    }
    // Feathers.service("revision")
    //   .get(task.id)
    //   .then((task) => {
    //     console.log(task, "tarea seleccionada");
    //     this.setState({ task });
    //   });
  }

  async initTask(task) {
    return await swal({
      title: "¿Iniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (res) => {
      if (res.value) {
        Feathers.service("revision")
          .patch(task.id, {
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 1,
            usuario_id: this.props.user.id,
          })
          .then((res) => {
            Feathers.service("revision")
              .get(res.id)
              .then((task) => this.setState({ task }));
          });

        return 0;
      } else {
        return 1;
      }
    });
  }

  async internInitTask(task) {
    return await swal({
      title: "¿Iniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (res) => {
      if (res.value) {
        await Feathers.service("revision").patch(task.id, {
          hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
          status_tarea_id: 1,
          usuario_id: this.props.user.id,
        });

        return 0;
      } else {
        return 1;
      }
    });
  }

  finishTask = async (task = this.state.task) => {
    console.log(task.id, "id tarea");
    swal({
      title: "¿Iniciar y finalizar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("revision")
          .patch(task.id, {
            //hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
            usuario_id: this.props.user.id,
          })
          .then(async (res) => {
            // this.fetchData()
            //TODO: SI LA TAREA TIENE NOTIFICACIONE ACTIVAS, CREAR UNA NOTI AL FINALIZAR ESTA TAREA)
            const checkNotification = await Feathers.service(
              "notification_tasks"
            ).get(1);
            if (checkNotification.activo) {
              Feathers.service("notificacion").create({
                mensaje: `Se ha finalizado una tarea de revisión #${task.id}`,
                perfil_id: "1",
                fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 4,
              });
            }

            this.setState(
              {
                task: null,
                loadingCheckButton: false,
              },
              () => this.fetchData(this.state)
            );
          })
          .catch((error) => {
            swal({
              title: "Esta tarea ya fue finalizada",
              text: "La tarea ya se finalizó. Recargue la pagina.",
              type: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
            console.log(error);
          });
      }
    });
  };

  restartTask() {
    swal({
      title: "¿Reiniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("revision")
          .patch(this.state.task.id, {
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            hora_fin: null,
            status_tarea_id: 1,
          })
          .then((res) => {
            this.fetchData();
          });
      }
    });
  }

  cancelTaskByIncidence(incidencia_id) {
    Feathers.service("revision")
      .patch(this.state.task.id, {
        incidencia_id,
        status_tarea_id: 3,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        Feathers.service("pedido").patch(this.state.task.pedido_id, {
          status_id: 6,
        });
        this.setState({ task: null });
        //notify.show('Incidencia registrada', 'success');
        swal({
          title: "Incidencia Registrada",
          type: "success",
          confirmButtonText: "OK",
        });
      });
  }

  handleInstaCheck = async (row) => {
    await this.setState({
      loadingCheckButton: true,
    });
    let response = await this.internInitTask(row.original);
    if (response === 1) return this.setState({ loadingCheckButton: false });
    await this.finishTask(row.original);
  };

  deleteOrder = (unidad) => {
    swal({
      title: "¿Está usted seguro?",
      text: "La orden se va a eliminar.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
    }).then(async (result) => {
      if (result.value) {
        const pedido_id = unidad.pedido_id;
        const rodillo_id = unidad.rodillo_id;

        const partidas_del_pedido = await Feathers.service("partida").find({
          query: {
            $limit: "-1",
            pedido_id: pedido_id,
          },
        });

        const partidas_ids = partidas_del_pedido.map((v) => v.id);

        const unidades_de_la_partida = await Feathers.service("rodillo").find({
          query: {
            $limit: "-1",
            partida_id: {
              $in: partidas_ids,
            },
          },
        });

        const unidades_ids = unidades_de_la_partida.map((v) => v.id);

        await this.setState({
          loading: true,
        });

        // baja de construccion

        try {
          let tarea_contruccion = await Feathers.service("construccion").find({
            query: {
              rodillo_id: {
                $in: unidades_ids,
              },
            },
          });

          if (tarea_contruccion.data.length >= 1) {
            let baja_construccion = await Feathers.service(
              "construccion"
            ).patch(
              null,
              {
                status_tarea_id: 3,
                cancel: 1,
              },
              {
                query: {
                  rodillo_id: {
                    $in: unidades_ids,
                  },
                },
              }
            );

            // let baja_construccion =  await Feathers.service('construccion').patch( null, {
            //     status_tarea_id : 3
            // }, {
            //     rodillo_id
            // })
          }
        } catch (error) {
          console.log("error en construccion");
          console.log(error);
        }

        let tarea_control_calidad = await Feathers.service(
          "control_calidad"
        ).find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_control_calidad.data.length >= 1) {
          let baja_control_calidad = await Feathers.service(
            "control_calidad"
          ).patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_corte = await Feathers.service("corte").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_corte.data.length >= 1) {
          let baja_corte = await Feathers.service("corte").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_desbaste = await Feathers.service("desbaste").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_desbaste.data.length >= 1) {
          let baja_desbaste = await Feathers.service("desbaste").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_desmonte = await Feathers.service("desmonte").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_desmonte.data.length >= 1) {
          let baja_desmonte = await Feathers.service("desmonte").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_desviste = await Feathers.service("desviste").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_desviste.data.length >= 1) {
          let baja_desviste = await Feathers.service("desviste").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        // vamos a handlear lo de la tabla pedido

        let baja_pedido = await Feathers.service("pedido").patch(pedido_id, {
          status_id: 6,
        });

        // seguiremos con la baja de rectificado

        let tarea_rectificado = await Feathers.service("rectificado").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_rectificado.data.length >= 1) {
          let baja_rectificado = await Feathers.service("rectificado").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_revision = await Feathers.service("revision").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_revision.data.length >= 1) {
          let baja_revision = await Feathers.service("revision").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        // vamos a handlear lo que pasa en la tabla rodillo

        let baja_rodillo = await Feathers.service("rodillo").patch(
          null,
          {
            status_tarea_id: 3,
          },
          {
            query: {
              id: {
                $in: unidades_ids,
              },
            },
          }
        );

        // vamos a handlear lo de los molinos
        // primero tenemos que traer las tareas de molino cuya partida sea parte del pedido y que no estén iniciadas

        const tarea_molino = await Feathers.service("molino").find({
          query: {
            partida_id: {
              $in: partidas_ids,
            },
            status_tarea_id: null,
            $limit: "-1",
          },
        });

        const molino_ids = tarea_molino.map((v) => v.id);

        if (molino_ids.length >= 1) {
          const baja_molino = await Feathers.service("molino").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                id: {
                  $in: molino_ids,
                },
              },
            }
          );
        }

        // vulcanizado

        const baja_vulcanizado = await Feathers.service(
          "pendiente_vulcanizado"
        ).remove(null, {
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        const incidencias_resuletas = await Feathers.service(
          "incidencia"
        ).patch(
          null,
          {
            solucion: "Orden cancelada",
          },
          {
            query: {
              rodillo_id: {
                $in: unidades_ids,
              },
            },
          }
        );

        await this.setState({
          loading: false,
        });

        this.fetchData(this.state);
      }
    });
  };

  openModal = (row) => {
    this.setState({
      modalOpened: true,
      currentRow: row.pedido ? row.pedido.partidas.observaciones : "",
    });
  };
  closeModal = () => {
    this.setState({
      modalOpened: false,
    });
  };

  renderActions = (row) => {
    row = row.original;
    return (
      <div>
        {/* <Button
          onClick={() => this.viewTask(row)}
          icon="play"
          size="mini"
          basic
          color="blue"
          content="Ver"
          style={{ backgroundColor: "#0098f0" }}
        /> */}
        {row.rodillo_id && (
          <Button
            onClick={() => this.deleteOrder(row)}
            icon="x"
            basic
            size="mini"
            color="red"
            // content="Cancelar"
          />
        )}
        <Button
          onClick={() => this.openModal(row)}
          icon="eye"
          size="mini"
          basic
          color="blue"
          content="Obser."
          style={{ backgroundColor: "#0098f0" }}
        />
      </div>
    );
  };

  handleCleanButton = (_) => {
    this.resetClientComponent();
    this.resetSaeComponent();
    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          client_id: null,
          ref_sae: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  handleSaeToggle = () => {
    this.setState(
      {
        showSae: !this.state.showSae,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(this.state.showSae);
  };

  onRodilloChange = (rodillo) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          rodilloValue: rodillo,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onPartidaChange = (partida) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          partidaValue: partida,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onPedidoChange = (pedido) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          pedidoValue: pedido,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onDateChange = (date) => {
    console.log("----- change date");
    this.setState(
      {
        filter: {
          ...this.state.filter,
          fechaEntrega: date,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  renderList() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h2" icon="book" content="Revisión e incidencias" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <TasksFilter
                    loading={this.state.loading}
                    filters={this.state.filters}
                    costumer={this.state.cliente || ""}
                    costumers={this.state.clientResults}
                    onSelectCostumer={this.handleClientResultSelect}
                    onSearchCostumerChange={this.handleClientSearchChange}
                    costumerResultRenderer={renderClientResults}
                    rodilloValue={this.state.rodilloValue}
                    onChangeRodillo={this.onRodilloChange}
                    partidaValue={this.state.partidaValue}
                    onChangePartida={this.onPartidaChange}
                    pedidoValue={this.state.pedidoValue}
                    onPedidoChange={this.onPedidoChange}
                    fechaEntrega={this.state.filter.fechaEntrega}
                    onDateChange={this.onDateChange}
                    materials={this.state.materialResults}
                    material={this.state.material ? this.state.material : ""}
                    handleMaterialResultSelect={this.handleMaterialResultSelect}
                    handleMaterialSearchChange={this.handleMaterialSearchChange}
                    renderMaterialSearchResults={renderMaterialSearchResults}
                  />
                  {/* <Form.Field>
                    <label>Cliente</label>
                    <Search
                      icon=""
                      loading={this.state.clientLoading}
                      value={this.state.client}
                      results={this.state.clientResults}
                      onResultSelect={this.handleClientResultSelect}
                      onSearchChange={this.handleClientSearchChange}
                      resultRenderer={renderClientResults}
                      placeholder="Nombre del cliente"
                      className="full-width"
                    />
                  </Form.Field> */}
                  {/* <Form.Field>
                    <label>SAE</label>
                    <Search
                      icon=""
                      loading={this.state.saeLoading}
                      value={this.state.sae}
                      results={this.state.saeResults}
                      onResultSelect={this.handleResultSaeSelect}
                      onSearchChange={this.handleSearchSaeChange}
                      resultRenderer={renderSearchSaeResults}
                      placeholder="SAE"
                      className="full-width"
                    />
                  </Form.Field> */}
                  {/* <Form.Field>
                    <label>Material</label>
                    <Search
                      icon=""
                      loading={this.state.materialLoading}
                      value={this.state.material}
                      results={this.state.materialResults}
                      onResultSelect={this.handleMaterialResultSelect}
                      onSearchChange={this.handleMaterialSearchChange}
                      resultRenderer={renderMaterialSearchResults}
                      placeholder="Material"
                      className="full-width"
                    />
                  </Form.Field> */}
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                  {/* <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        exportToXls(this.state.checks, "task_checks");
                      }}
                    />
                  </Form.Field> */}
                  <Form.Field>
                    <label>Mostrar OT</label>
                    {/* <input type="checkbox" name="showSAE"/> */}
                    <Toggle
                      icons={false}
                      onClick={() => {
                        this.handleSaeToggle();
                      }}
                    ></Toggle>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h5"
                block
                icon="info circle"
                content="Si el pedido contiene dos o más partidas, los datos que se muestran corresponden únicamente a la primera partida"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ReactTable
                columns={
                  this.state.showSae
                    ? this.state.tableColumns
                    : this.state.tableColumns.slice(1)
                }
                manual
                data={this.state.checks}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                // onFetchData={(state) => {
                //   this.fetchData(state);
                //   this.interval = setInterval(() => {
                //     window.location.reload();
                //     //console.log('recargando...')
                //   }, 300000)
                // }}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState(
                    {
                      columna: column,
                      sortDirection: direction,
                    },
                    () => {
                      console.log("res", this.state);
                    }
                  );
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ObservationModal
          data={this.state.currentRow}
          isOpened={this.state.modalOpened}
          onClose={this.closeModal}
        />
      </div>
    );
  }

  render() {
    if (this.state.ready) {
      if (this.state.task) {
        return (
          <CheckTask
            task={this.state.task}
            initTask={this.initTask}
            goBack={this.goBack}
            finishTask={this.finishTask}
            cancelTaskByIncidence={this.cancelTaskByIncidence}
          />
        );
      }

      return this.renderList();
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CheckContainer);
